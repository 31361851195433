import axios from 'axios'

const request = axios.create({
    baseURL: location.origin,
    timeout: 10000,
    method: 'get'
})

// 添加响应拦截器
request.interceptors.response.use(response => {
    return response.data
})

export default request